import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth';
import { facilities } from './facilities';
import { merchant } from './merchant';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    merchant,
    facilities,
  },
  state: {},
  mutations: {},
  actions: {
    async initialiseStore({ dispatch }) {
      dispatch('auth/defaultUser');
      dispatch('merchant/defaultMerchant');
    },
    clearAuth({ state, commit }) {
      commit('auth/SET_CURRENT_USER', null);
      commit('merchant/SET_CURRENT_MERCHANT', null);
      commit('merchant/SET_SUBSCRIPTION_STATUS', false);
      commit('merchant/SET_SUBSCRIBED_TOOLS', null);
      commit('merchant/SET_TEAM_MERCHANTS', null);
      commit('facilities/SET_FACILITIES', null);
      commit('facilities/CLEAR_FACILITIES');
    },
  },
  getters: {},
});
