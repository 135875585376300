import { db_9 } from "@/firebase/init";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
const facilities = {
  namespaced: true,
  state: {
    facilities: null,
    coupons: {},
    teamCoupons: {},
    orders: {},
    dragMenus: {},
    qrSpots: {},
    privileges: [],
    rawMaterials: {},
  },
  mutations: {
    SET_FACILITIES: (state, facilities) => {
      state.facilities = facilities;
    },
    SET_PRIVILEGES: (state, privileges) => {
      state.privileges = privileges;
    },
    SET_COUPONS: (state, { facilityId, coupons }) => {
      state.coupons[facilityId] = coupons;
    },
    SET_TEAM_COUPONS: (state, { facilityId, coupons }) => {
      state.teamCoupons[facilityId] = coupons;
    },
    /*  SET_ORDERS: (state, { facilityId, orders }) => {
      state.orders[facilityId] = orders;
    }, */

    SET_MENU: (state, { facilityId, data }) => {
      state.dragMenus[facilityId] = data;
      console.log(state.dragMenus[facilityId])
    },
    UPDATE_PRODUCT_FIELD(state, { facilityId, productId, fieldName, fieldValue  }) {
      if (state.dragMenus[facilityId]) {
        const product = state.dragMenus[facilityId].menuItemsWithoutCategory.find(item => item._id === productId);
        if (product) {
          product[fieldName] = fieldValue;
        }
      }
    },
    SET_QR_SPOTS: (state, { facilityId, spots }) => {
      state.qrSpots[facilityId] = spots;
    },
    SET_CATEGORIES: (state, { facilityId, categories }) => {
      state.dragMenus[facilityId].menuCategories = categories;
    },
    SET_PRODUCTS: (state, { facilityId, products }) => {
      state.dragMenus[facilityId].menuItemsWithoutCategory = products;
    },
    SET_TAXES: (state, { facilityId, taxes }) => {
      state.dragMenus[facilityId].menuItemTaxes = taxes;
    },
    CLEAR_MENU: (state, { facilityId }) => {
      delete state.dragMenus[facilityId];
    },
    CLEAR_FACILITIES: (state) => {
      state.facilities = null;
      state.coupons = {};
      state.teamCoupons = {};
      state.orders = {};
      state.dragMenus = {};
      state.qrSpots = {};
    },
    SET_RAW_MATERIALS: (state, { facilityId, materials }) => {
      state.rawMaterials[facilityId] = materials;
    },
  },
  actions: {
    async setFacilities({ commit, rootState }) {
      if (!rootState.auth.user) {
        return false;
      }
      let merchantId = rootState.merchant.currentMerchant.id;
      if(merchantId){
        console.log("<< M UP >>", merchantId.slice(0, 4));

      }else{

        console.log("MEM")
      }
    

      if (rootState.auth.user.isOwner) {
        let facilities = {};
        const q = query(
          collection(db_9, "merchant_facilities"),
          where("merchant_id", "==", merchantId)
        );
        onSnapshot(q, (res) => {
          if (!res.empty) {
            res.forEach((doc) => {
              var data = doc.data();
              data.id = doc.id;
              facilities[doc.id] = data;
            });
            commit("SET_FACILITIES", facilities);
          }
        });
      } else {
        let facilities = {};

        const businessRef = doc(
          db_9,
          "users",
          rootState.auth.user.uid,
          "employed_business",
          merchantId
        );

        await getDoc(businessRef).then(async (userFacilities) => {
          if (userFacilities.exists()) {
            let arrayValues = Object.values(userFacilities.data());
            let privileges = {};

            for (let i = 0; i < arrayValues.length; i++) {
              const data = arrayValues[i];
              // facilities[data.id] = data;
              const teamRef = doc(
                db_9,
                "merchant_facilities",
                data.id,
                "team",
                rootState.auth.user.uid
              );

              await getDoc(teamRef).then((member) => {
                privileges[data.id] = member.data()?.privileges || [];
              });

              const facilityRef = doc(db_9, "merchant_facilities", data.id);

              /* await getDoc(facilityRef).then((facility) => {
                var facilityData = facility.data();
                facilityData.id = facility.id;
                facilities[facility.id] = facilityData;
              }); */

              await new Promise((resolve, reject) => {
                onSnapshot(facilityRef, (facility) => {
                  if (facility.exists()) {
                    var facilityData = facility.data();
                    facilityData.id = facility.id;
                    facilities[facility.id] = facilityData;
                  }
                  resolve(facility);
                });
              });

              /* onSnapshot(facilityRef, (facility) => {
                if (facility.exists()) {
                  var facilityData = facility.data();
                  facilityData.id = facility.id;
                  facilities[facility.id] = facilityData;
                }
              }); */
            }
            commit("SET_PRIVILEGES", privileges);
          }
          commit("SET_FACILITIES", facilities);
        });
      }
    },
    async setCoupons({ commit }, { facilityId, coupons }) {
      commit("SET_COUPONS", { facilityId, coupons });
    },
    async setTeamCoupons({ commit }, { facilityId, coupons }) {
      commit("SET_TEAM_COUPONS", { facilityId, coupons });
    },
    /* async setOrders({ commit }, { facilityId, orders }) {
      commit("SET_ORDERS", { facilityId, orders });
    }, */
    async setMenu({ commit }, { facilityId, data }) {
      commit("SET_MENU", { facilityId, data });
    },
    async updateProductField({ commit }, { facilityId, productId, fieldName, fieldValue }) {
      console.log(fieldName)
      console.log(fieldValue)
      commit("UPDATE_PRODUCT_FIELD", { facilityId, productId, fieldName, fieldValue });
    },
    async setCategories({ commit }, { facilityId, categories }) {
      commit("SET_CATEGORIES", { facilityId, categories });
    },
    async setProducts({ commit }, { facilityId, products }) {
      commit("SET_PRODUCTS", { facilityId, products });
    },
    async setTaxes({ commit }, { facilityId, taxes }) {
      commit("SET_TAXES", { facilityId, taxes });
    },
    async clearMenu({ commit }, { facilityId }) {
      commit("CLEAR_MENU", { facilityId });
    },
    async setQrSpots({ commit }, { facilityId, spots }) {
      commit("SET_QR_SPOTS", { facilityId, spots });
    },

    async setRawMaterials({ commit }, { facilityId, materials }) {
      commit("SET_RAW_MATERIALS", { facilityId, materials });
    },
  },
  getters: {
    getFacilities(state) {
      console.log("state",state)
      return state.facilities;
    },
    getPrivileges(state) {
      return state.privileges;
    },
    getFacilityCoupons: (state) => (facilityId) => {
      return state.coupons[facilityId];
    },
    getFacilityTeamCoupons: (state) => (facilityId) => {
      return state.teamCoupons[facilityId];
    },
    getFacilityOrders: (state) => (facilityId) => {
      return state.orders[facilityId];
    },
    getFacilityMenu: (state) => (facilityId) => {
      return state.dragMenus[facilityId];
    },
    getFacilityQrSpots: (state) => (facilityId) => {
      return state.qrSpots[facilityId];
    },

    getRawMaterials: (state) => {
      return state.rawMaterials;
    },
  },
};

export { facilities };
